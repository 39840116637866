/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userAlaCarte.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class Product(
    @SerialName("id")
    val id: Int?,

    @SerialName("title")
    val title: String?,

    @SerialName("description")
    val description: String?,

    @SerialName("images")
    val images: List<Images>?,

    @SerialName("product_sku")
    val productSku: String?,

    @SerialName("actual_price")
    val actualPrice: Float?,

    @SerialName("real_price")
    val realPrice: Float?,

    @SerialName("terms_conditions")
    val termsConditions: String?,

    @SerialName("link")
    val link: String?,

    @SerialName("product_languages")
    val productLanguages: List<Languages>?
) : Model()
