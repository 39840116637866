/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.publicOffers.internal

import features.publicOffers.models.UserOffersModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class PublicOffersServiceImpl : PublicOffersService {

    private val publicOffersRequest = PublicOffersRequest()

    override suspend fun getPublicOffers(
        overrideHeaders : Map<String, String>?,
    ): Result<UserOffersModel> {
        return publicOffersRequest.getPublicOffers(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
