/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.publicOffer.internal

import features.publicOffer.models.PublicOfferModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class PublicOfferServiceImpl : PublicOfferService {

    private val publicOfferRequest = PublicOfferRequest()

    override suspend fun getPublicOffer(
        overrideHeaders : Map<String, String>?,
        offerSlug: String    ): Result<PublicOfferModel> {
        return publicOfferRequest.getPublicOffer(
            overrideHeaders = overrideHeaders,
            offerSlug = offerSlug        ).toModel()
    }
}
