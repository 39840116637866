/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.business.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@ExperimentalJsExport
@JsExport
@Serializable
data class Branch(
    /** Unique ID for this branch */
    @SerialName("id")
    val id: String?,

    /** The name of this branch */
    @SerialName("name")
    val name: String?,

    /** The website for this branch */
    @SerialName("web_site")
    val webSite: String?,

    @SerialName("phone")
    val phone: BranchPhone?,

    @SerialName("logo")
    val logo: Image?,

    @SerialName("cover_photo")
    val coverPhoto: Image?,

    @SerialName("address")
    val address: BranchAddress?
)
