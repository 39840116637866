/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.postOfferFacebookShare.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * User Profile model class containing user data
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class UserProfileDataModel(
    /** Unique user ID. */
    @SerialName("id")
    val id: String?,

    /** Email address identifying this user. */
    @SerialName("email")
    val email: String?,

    /** Phone number associated with this user. */
    @SerialName("phone")
    val phone: String?,

    /** First name associated with this user. */
    @SerialName("first_name")
    val firstName: String?,

    /** Last name associated with this user. */
    @SerialName("last_name")
    val lastName: String?,

    /** Unique QR code identifying this user. */
    @SerialName("qrcode")
    val qrcode: String?,

    /** Gender associated with this user. */
    @SerialName("gender")
    val gender: String?,

    /** Date of birth associated with this user. */
    @SerialName("birth_date")
    val birthDate: String?,

    /** Primary language assocaited with this user. */
    @SerialName("language")
    val language: String?,

    /** Country code associated with this user. */
    @SerialName("country_code")
    val countryCode: String?,

    /** Profile photo url associated with this user. */
    @SerialName("profile_photo")
    val profilePhoto: String?,

    /** Date the account for this user was created. */
    @SerialName("created_at")
    val createdAt: String?,

    /** Date the account for this user was last updated. */
    @SerialName("updated_at")
    val updatedAt: String?,

    /** Denotes whether this account has been enabled. */
    @SerialName("enabled")
    val enabled: Boolean?,

    /** Denotes whether the email address for this user has been verified. */
    @SerialName("email_verified")
    val emailVerified: Boolean?,

    /** Denotes whether the phone number for this user has been verified. */
    @SerialName("phone_verified")
    val phoneVerified: Boolean?
) : Model()
