/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")

package features.publicOffers.internal

import features.publicOffers.models.UserOffersModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.AsyncUseCase
import features.publicOffers.internal.PublicOffersUseCase.Params

internal class PublicOffersUseCase : AsyncUseCase<UserOffersModel, Params>() {

    internal class Params(
       val overrideHeaders : Map<String, String>? = null,

    )

    private val publicOffersService: PublicOffersService = PublicOffersServiceImpl()

    override suspend fun run(params: Params): Result<UserOffersModel> {
        return publicOffersService.getPublicOffers(
            overrideHeaders = params.overrideHeaders,

        )
    }
}
