/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.publicBranches.internal

import features.publicBranches.models.PublicBranchesModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class PublicBranchesServiceImpl : PublicBranchesService {

    private val publicBranchesRequest = PublicBranchesRequest()

    override suspend fun getPublicBranches(
        overrideHeaders : Map<String, String>?,
    ): Result<PublicBranchesModel> {
        return publicBranchesRequest.getPublicBranches(
            overrideHeaders = overrideHeaders,
        ).toModel()
    }
}
