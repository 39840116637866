/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.draws.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * A business banner.
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class Draw(
    /** Unique draw id. */
    @SerialName("id")
    val id: Int?,

    /** start date of the draw. */
    @SerialName("start_date")
    val startDate: String?,

    /** end date of the draw. */
    @SerialName("end_date")
    val endDate: String?,

    /** The draw title. */
    @SerialName("title")
    val title: String?,

    /** The draw description. */
    @SerialName("description")
    val description: String?,

    /** The images attached to this draw. There are 3 available sizes: thumbnail, medium, and large.  */
    @SerialName("images")
    val images: List<Image>?,

    /** If this draw is enabled or not. */
    @SerialName("enabled")
    val enabled: Boolean?,

    /** If this draw is expired or not. */
    @SerialName("is_expired")
    val isExpired: Boolean?,

    @SerialName("draw_branches")
    val drawBranches: List<DrawBranches>?,

    @SerialName("languages")
    val languages: List<Languages>?,

    @SerialName("draw_rules")
    val drawRules: List<DrawRule>?,

    @SerialName("draw_prizes")
    val drawPrizes: List<DrawPrize>?
)
