/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.publicReward.internal

import features.publicReward.models.PublicRewardModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class PublicRewardServiceImpl : PublicRewardService {

    private val publicRewardRequest = PublicRewardRequest()

    override suspend fun getPublicReward(
        overrideHeaders : Map<String, String>?,
        rewardSlug: String    ): Result<PublicRewardModel> {
        return publicRewardRequest.getPublicReward(
            overrideHeaders = overrideHeaders,
            rewardSlug = rewardSlug        ).toModel()
    }
}
