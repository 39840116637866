/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE")
@file:OptIn(ExperimentalJsExport::class)
package features.publicOffer

import kangaroorewards.appsdk.core.api.Api
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.domain.Result.Idle
import kangaroorewards.appsdk.core.domain.Result.Loading
import kangaroorewards.appsdk.core.domain.SerializedResult
import kangaroorewards.appsdk.core.domain.toJsonResult
import kangaroorewards.appsdk.core.utils.CFlow
import kangaroorewards.appsdk.core.utils.asCFlow
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.mapLatest
import kotlinx.serialization.InternalSerializationApi
import kotlin.js.ExperimentalJsExport
import okio.FileSystem
import features.publicOffer.internal.PublicOfferUseCase
import features.publicOffer.internal.PublicOfferUseCase.Params
import features.publicOffer.models.PublicOfferModel


typealias PublicOfferState = CFlow<PublicOfferUseCaseResult?>

typealias PublicOfferUseCaseResult = Result<PublicOfferModel>

fun PublicOfferUseCaseResult.serializePublicOfferApiResult(): SerializedResult<String> {
    return this.toJsonResult<PublicOfferModel>()
}

/**
 * Serializes [PublicOfferState]'s data and returns a new
 * [CFlow] containing data serialized as a JSON [String].
 */
@OptIn(ExperimentalCoroutinesApi::class)
fun PublicOfferState.serializePublicOfferState(): CFlow<SerializedResult<String>?> {
    return this.mapLatest {
        it?.toJsonResult<PublicOfferModel>()
    }.asCFlow()
}

/**
 * Get a public offer [Api] class
 */
class PublicOfferApi : Api() {

    val publicOfferState: PublicOfferState
        get() = _publicOfferState.asCFlow()

    companion object {
        private val _publicOfferState: MutableStateFlow<PublicOfferUseCaseResult> = MutableStateFlow(Idle())
    }

    private val publicOfferUseCase = PublicOfferUseCase()

    /**
     * Get a public offer
     */
    suspend fun getPublicOffer(
        overrideHeaders: Map<String, String>? = null,
        offerSlug: String
    ): Result<PublicOfferModel> {
        /* set state to Loading as soon as function is called */
        _publicOfferState.value = Loading()
        val result = this.publicOfferUseCase(
            Params(
                overrideHeaders = overrideHeaders,
                offerSlug = offerSlug
            )
        ) { result ->
            _publicOfferState.value = result
            return@publicOfferUseCase result
        }
        return result
    }

}
